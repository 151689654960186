angular.module('app').directive("cartWidget", function (CartService) {
    return {
        restrict: 'E',
        template:'<div style="position: relative; float:right; width: 400px;height:140px;margin-top: -60px; margin-right: 0px; border-width: 5px; border-color: #f3f3f3; border-style: solid; background-color: #ffffff" ng-show="recentAdds.length > 0"><div class="row"><div class="col-md-12"><table class="table table-hover"><tr ng-repeat="employee in recentAdds.slice(0,4)"><td style="border-top: 0px; padding: 2px;"><button ng-click="removeFromCart(employee)" type="button" class="btn btn-default btn-xs"><span class="glyphicon glyphicon-minus"></span></button></td><td style="border-top: 0px; padding: 2px;">{{employee.firstName}}</td><td style="border-top: 0px; padding: 2px;">{{employee.lastName}}</td><td align="right" style="border-top: 0px; padding: 2px; padding-right: 6px;">{{employee.ssn}}</td></tr></table></div></div><div class="row" style="position: absolute; bottom: 0px; margin-left: 0px; width: 390px; background-color: #f5f5f5"><div class="btn-toolbar" style="bottom: 1px; margin-left: 80px;"><div class="btn-group"><button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" style="height: 24px; padding-top: 1px">{{\'Report\' | translate}} <span class="caret"></span></button><ul class="dropdown-menu" role="menu"><li><a href="#/salarybulk">{{\'Update-Of-Salary\' | translate}}</a></li><li><a href="#/atwbulk?atwOnly=true">{{\'Statement of Work Ability\' | translate}}</a></li></ul></div><button type="button" confirm-before-execute="removeAll()" confirm-message="{{\'Really-Clear-List\' | translate}}" class="btn btn-danger" style="height: 24px; margin-left: 60px; padding-top: 1px"><span class="glyphicon glyphicon-trash"></span> {{\'Clear-All\' | translate}}</button></div></div></div>',
        link: function (scope) {
            scope.employees = CartService.list();

            scope.removeFromCart = function (employee) {
                CartService.remove(employee);
            };

            scope.removeAll = function () {
                CartService.clear();
            };
        }
    };
});
