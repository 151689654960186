var module = angular.module('contact', []);

module.controller("ContactCtrl",
    function($scope, $uibModal, SelectedCompany, Contacts) {

        $scope.contacts = null;
        $scope.selectedCompany = null;

        $scope.open = function () {
            $scope.selectedCompany = SelectedCompany.get();
            $scope.contacts = Contacts.query();

            var modalInstance = $uibModal.open({
                template:'<div class="modal-header"><h1>{{ \'CONTACT_TITLE\' | translate }}</h1><h1><small class="company-heading">{{ selectedCompany.name }}</small></h1></div><div class="modal-body"><div class="list-group"><a ng-repeat="contact in contacts" class="list-group-item" ng-href="mailto:{{ contact.mail }}"><div ng-if="row.type !== \'CaseGroup\'"><div class="list-group-item-heading">{{ contact.type | translate }}</div><div class="list-group-item-text"><small class="text-muted">{{ contact.name }}</small></div><div class="list-group-item-text"><small class="text-muted">{{ contact.phone }}</small></div><div class="list-group-item-text"><small class="text-muted">{{ contact.mail }}</small></div></div></a></div><a href="http://www.maxm.se/4_3.php" target="_blank">{{"Contact MM" | translate}}</a></div><div class="modal-footer"><button type="button" class="btn btn-default" ng-click="$close()">{{"Close" | translate}}</button></div>',
                scope: $scope
            });

            var removeListener = $scope.$on("$locationChangeStart", function() {
                removeListener();
                modalInstance.dismiss();
            });

            modalInstance.result['finally'](function (result) {
                removeListener();
            });

        };
    }
);

