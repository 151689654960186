const app = angular.module('app');

app
    .controller('BankIdAuthorizationController', function(
        $scope,
        $window
    ) {

        $scope.authRedirect = function() {
            $window.location.href = './api/auth/bankid-login';
        };

    })

    .directive('maxsecurityBankIdAuth', function() {
        return {
            template:'<form name="sbidForm" ng-submit="authRedirect()"><div class="form-group"><button class="btn btn-primary btn-block" type="submit" translate="Sign in"></button></div><a href="https://support.bankid.com/sv" target="_blank" translate="login_bankid_help"></a></form>',
            restrict: 'E',
            controller: 'BankIdAuthorizationController'
        };
    });
