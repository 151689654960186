const module = angular.module('csDateValidators', []);

module.directive('validateDateAfter', () => ({
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ctrl) {
        var attributeName = 'validateDateAfter',
            isDatesInCorrectOrder = function(startDate, endDate) {
                // If at least one of the dates is missing we return true
                // since existence should be checked with a different validation.
                if (!startDate || !endDate) {
                    return true;
                }
                return moment(startDate) < moment(endDate);
            },
            validate = function(endDate) {
                ctrl.$setValidity(attributeName, (scope.command ? isDatesInCorrectOrder(scope.command.start, endDate): true));
                return endDate;
            };

        ctrl.$parsers.unshift(validate);
        ctrl.$formatters.push(validate);

        attrs.$observe(attributeName, function() {
            return validate();
        });
    },
}));

module.directive('validateDateBefore', () => ({
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ctrl) {
        var attributeName = 'validateDateBefore',
            isDatesInCorrectOrder = function(startDate, endDate) {
                // If at least one of the dates is missing we return true
                // since existence should be checked with a different validation.
                if (!startDate || !endDate) {
                    return true;
                }
                return moment(startDate) < moment(endDate);
            },
            validate = function(startDate) {
                ctrl.$setValidity(attributeName, (scope.command ? isDatesInCorrectOrder(startDate, scope.command.end): true));
                return startDate;
            };

        ctrl.$parsers.unshift(validate);
        ctrl.$formatters.push(validate);

        attrs.$observe(attributeName, function() {
            return validate();
        });
    },
}));
