const resources = angular.module('csResources', ['ngResource']);

resources.factory('Contacts', $resource => $resource('./api/companies/contacts', {}, {}));
resources.factory('Preferences', $resource => $resource('./api/preference', {}, {}));
resources.factory('Imports', $resource =>
    $resource(
        './api/imports/:id',
        {},
        { history: { method: 'GET', isArray: true }, events: { method: 'GET', isArray: true } }
    )
);

resources.factory('Stats', $resource => $resource('./api/stats/:category', {}, {}));
resources.factory('Employees', $resource => $resource('./api/employees/', {}));
resources.factory('EmployeesSimpleQuery', $resource => $resource('./api/employees/simple/', {}));
resources.factory('Employee', $resource =>
    $resource('./api/employees/:anstallningsgruppExterntId/:ssn', {})
);

resources.factory('EmployeeService', [
    '$http',
    'WorkflowCase',
    function($http, WorkflowCase) {
        var service = {};

        service.atwCertRequired = function(newSalary) {
            return $http.post('./api/employees/atwStatementRequired', newSalary);
        };

        service.attestWorkAbility = function(wcaseData) {
            var wcase = new WorkflowCase({ id: wcaseData.id });
            wcase.eventType = 'Approval';
            wcase.type = wcaseData.type;
            wcase.payload = wcaseData.payload;
            wcase.employeeSSN = wcaseData.employee.ssn;
            if (angular.isUndefined(wcase.id)) {
                wcase.anstallningsgruppExterntId = wcaseData.employee.anstallningsgruppExterntId;
            }

            return wcase.$save();
        };

        service.attestWorkAbilityBulk = function(employees, success) {
            var request = [];

            _.each(employees, employee => {
                _.each(employee.atws, atw => {
                    var wcaseData = JSON.parse(atw.wCase);

                    var wcase = new WorkflowCase({ id: wcaseData.id });
                    wcase.eventType = 'Approval';
                    wcase.type = wcaseData.type;
                    wcase.payload = wcaseData.payload;
                    wcase.employeeSSN = wcaseData.employee.ssn;
                    if (angular.isUndefined(wcaseData.id)) {
                        wcase.anstallningsgruppExterntId =
                            wcaseData.employee.anstallningsgruppExterntId;
                    }

                    request.push(wcase);
                });
            });
            return $http.post('./api/cases/bulk', request);
        };

        service.postNewSalaries = function(employees) {
            var payloads = _.map(employees, function(employee) {
                var payload = {};
                payload.key = {
                    anstallningsgruppExterntId: employee.anstallningsgruppExterntId,
                    ssn: employee.ssn,
                };
                payload.anstallningExterntId = employee.anstallningExterntId;
                payload.amountSEK = employee.newSalary.amountSEK;
                payload.effectiveDate = employee.effectiveDate;
                payload.type = employee.type;
                payload.note = employee.note;
                payload.statement = employee.atw ? employee.atws[0].statement : null;
                payload.atwNote = employee.atw ? employee.atws[0].atwNote: null;
                return payload;
            });

            return $http.post('./api/employees/salary', { payloads: payloads });
        };

        return service;
    },
]);

resources.factory('EmployeeSalary', $resource =>
    $resource(
        './api/employees/:anstallningsgruppExterntId/:ssn/salary',
        { anstallningsgruppExterntId: '@anstallningsgruppExterntId', ssn: '@ssn' },
        {}
    )
);

resources.factory('AktuellLon', $resource =>
    $resource(
        './api/employees/aktuellLon',
        {},
        {
            fetchSalaries: {
                method: 'POST',
                isArray: true,
            },
        }
    )
);

resources.factory('BulkSalary', $resource => $resource('./api/employees/salary', {}));

resources.factory('BulkAtwStatementRequired', $resource =>
    $resource('./api/employees/atwStatementRequiredBulk', {})
);

resources.factory('EmployeeAbsence', $resource =>
    $resource(
        './api/employees/:anstallningsgruppExterntId/:ssn/absence',
        { anstallningsgruppExterntId: '@anstallningsgruppExterntId', ssn: '@ssn' },
        {}
    )
);

resources.factory('EmployeeCases', $resource =>
    $resource(
        './api/employees/:anstallningsgruppExterntId/:ssn/cases',
        { anstallningsgruppExterntId: '@anstallningsgruppExterntId', ssn: '@ssn' },
        {}
    )
);

resources.factory('Benefits', $resource =>
    $resource('./api/employees/:anstallningsgruppExterntId/:ssn/benefits', {}, {})
);

resources.factory('NewEmployee', $resource => $resource('./api/employees', {}, {}));

resources.factory('Company', $resource =>
    $resource('./api/companies/:anstallningsgruppExterntId', {}, {})
);

resources.factory('Companies', $resource => $resource('./api/companies/', {}, {}));

resources.factory('SelectedCompany', $resource => $resource('./api/companies/selected', {}, {}));

resources.factory('CostCenters', $resource =>
    $resource('./api/companies/:anstallningsgruppExterntId/cost_centers', {}, {})
);

resources.factory('User', $resource => {
    return $resource('./api/user', {});
});

resources.factory('roleCheck', $resource =>
    $resource('./api/user/hasRole/:roleName', {}, { get: { method: 'GET', isArray: false } })
);

resources.factory('WorkflowCases', $resource => $resource('./api/cases', {}, {}));

resources.factory('CaseGroup', $resource => $resource('./api/cases/group/:category', {}, {}));

resources.factory('WorkflowCase', $resource =>
    $resource(
        './api/cases/:id',
        { id: '@id' },
        {
            approve: { method: 'POST', params: { eventType: 'Approval' } },
            reject: { method: 'POST', params: { eventType: 'Rejection' } },
        }
    )
);

resources.factory('Version', $resource => $resource('./api/system/version', {}, {}));
resources.factory('Features', $resource => $resource('./api/system/features', {}, {}));
resources.factory('InfoMessage', $resource => $resource('./api/system/infoMessage', {}, {}));

resources.factory('EmployeeSearchService', (Employees, EmployeesSimpleQuery) => {
    var service = {};

    service.result = {};

    service.searchSpec = {
        mode: 'name_ssn',
    };

    service.query = function(query) {
        this.searchSpec = query;
        service.result = Employees.get(query);
        return service.result;
    };

    service.transientQuery = function(query) {
        return EmployeesSimpleQuery.get(query);
    };

    service.appendQuery = function() {
        var appendQuery = _.extend({}, this.searchSpec);
        appendQuery.from = this.result.hits.length;
        var appendResult = Employees.get(appendQuery);
        appendResult.$promise.then(function(more) {
            Array.prototype.push.apply(service.result.hits, more.hits);
        });
        return appendResult.$promise;
    };

    service.selectedCompany = null;

    service.selectedKostnadsstalle = null;

    return service;
});

resources.factory('CompanyRedirect', $resource => $resource('./api/heartbeat/redirect/:organizationNumber', {}, { get: { method: 'GET', isArray: false } }));

resources.factory('CompanyService', (Companies, Preferences, CompanyRedirect, $q, $location,  $timeout, Message) => {
    let service = {};
    service.companies = Companies.query();
    service.preferences = Preferences.get();
    service.selectedCompany = null;

    service.companiesNotEmptyPromise  = function() {
        return service.companies.$promise.then(companies => {
            return service.preferences.$promise.then(preferences => {
                console.log("companiesNotEmptyPromise: preferences" + preferences.anstallningsgruppExterntId + " " + service.preferences.anstallningsgruppExterntId);
                if (companies.length <= 0) {
                    return Promise.reject(new Error('NoCompanies'));
                }
                service.selectedCompany = service.getByAnstallningsgruppExterntId(preferences.anstallningsgruppExterntId);

                if (!service.selectedCompany ) {
                    console.debug("Initialising preferences with first company")
                    service.selectCompanyAndReloadBasePath(service.companies[0]);
                }
                else if (service.selectedCompany.heartbeat) {
                    console.debug("Diverting to root path for heartbeat company")
                    $location.path("/");
                }
                return companies;
            });
        });
    };


    service.getByAnstallningsgruppExterntId = function(anstallningsgruppExterntId) {
        if (!anstallningsgruppExterntId) {
            console.debug("getByAnstallningsgruppExterntId : anstallningsgruppExterntId was undefined")
            return undefined;
        }

        return _.find(service.companies, function(company) {
            if (!company.anstallningsgruppExterntId) {
                return false;
            }
            return company.anstallningsgruppExterntId === anstallningsgruppExterntId;
        });
    };

    service.selectCompanyAndReloadBasePath = function(company) {
        let newPath = "/" + $location.path().split('/')[1];
        console.debug("selectCompanyAndReloadBasePath path will change to " + newPath);
        service.selectCompanyAndReloadWithNewPath(company,newPath);
    };

    service.selectCompanyAndReloadWithSamePath = function(company) {
        let currentPath = $location.path();
        console.debug("Reload with currentPath " + currentPath);
        service.selectCompanyAndReloadWithNewPath(company,currentPath);
    };

    service.selectCompanyAndReloadWithNewPath = function(company, newPath) {
        console.debug("Selecting to work with company "+ company.anstallningsgruppExterntId )

        service.selectedCompany = company;
        service.preferences.anstallningsgruppExterntId = company.anstallningsgruppExterntId ;
        console.debug("saving preferences " + service.preferences.anstallningsgruppExterntId);

        let rememberAnstallningsgruppExterntId =  service.preferences.anstallningsgruppExterntId;
        service.preferences.$save().then(function() {
            service.preferences.anstallningsgruppExterntId = rememberAnstallningsgruppExterntId;
            let currentPath = $location.path();
            console.debug("Current path =" + currentPath);
            console.debug("New path =" + newPath);

            let reloadDelay = 0;
            if (newPath !== currentPath) {
                $location.path(newPath);
                reloadDelay=10;
            }
           if ($location.search().company) {
               console.debug("query for preferred company" );
               $location.search("company","preferred")
               reloadDelay = 10;
           }

            $timeout(function() {
                // Reload after delay
                // The delay gives time for the async location change above to complete before reloading the application
                location.reload();
            }, reloadDelay);

        }).catch(function(error) {
            service.preferences.anstallningsgruppExterntId = rememberAnstallningsgruppExterntId;
            console.error("Error saving preferences:", error);
        });
    };


    service.openHeartbeat = function({ organizationNumber }) {
        if (!organizationNumber) {
            return Promise.reject(new Error('Organization number is required.'));
        }

        return CompanyRedirect.get({ organizationNumber }).$promise
            .then(({ redirectUri }) => {
                if (!redirectUri) {
                    throw new Error('Redirect URI is missing.');
                }
                return window.open(redirectUri, 'heartbeat');
            })
            .catch(error => {
                Message.error('','redirect_error');
                console.error('Error opening heartbeat application:', error);
                throw error;
            });
    };

    return service;
});

resources.factory('PreferencesService', Preferences => ({
    preferences: Preferences.get(),
}));

resources.factory('InsuranceCompanySummaries', $resource =>
    $resource('./api/benefits/summaries/by_ins_company', {}, {})
);

resources.factory('SalaryExchanges', $resource =>
    $resource('./api/benefits/salary_exchanges', {}, {})
);

resources.factory('Insurances', $resource =>
    $resource('./api/benefits/:issuerOrgNo/insurances', {}, {})
);

resources.factory('BenefitService', (Insurances, InsuranceCompanySummaries, SalaryExchanges) => {
    var service = {};

    var companySummaries = [];

    var salaryExchanges = [];

    var insurances = [];

    var lastKey;

    service.type = undefined;

    service.getInsuranceCompanySummaries = function(orgNos) {
        if (!orgNos) {
            return companySummaries;
        }
        companySummaries = InsuranceCompanySummaries.query({ orgNo: orgNos });
        return companySummaries;
    };

    service.getSalaryExchanges = function(orgNos) {
        if (!orgNos) {
            return salaryExchanges;
        }
        salaryExchanges = SalaryExchanges.query({ orgNo: orgNos });
        return salaryExchanges;
    };

    service.getInsuranceList = function(issuerOrgNo, orgNos) {
        if (!orgNos || !issuerOrgNo) {
            throw 'org no or issuer org no is missing';
        }
        if (useLoaded(issuerOrgNo, orgNos)) {
            return insurances;
        }
        insurances = Insurances.query({ issuerOrgNo: issuerOrgNo, orgNo: orgNos });
        insurances.$promise.then(function() {
            setLastKey(issuerOrgNo, orgNos);
        });
        return insurances;
    };

    var setLastKey = function(issuerOrgNo, orgNo) {
        if (_.isArray(orgNo)) {
            lastKey = issuerOrgNo + '-all';
        } else {
            lastKey = issuerOrgNo + '-' + orgNo;
        }
    };

    var useLoaded = function(issuerOrgNo, orgNo) {
        if (_.isArray(orgNo)) {
            return lastKey === issuerOrgNo + '-all';
        }
        return lastKey === issuerOrgNo + '-' + orgNo;
    };

    return service;
});
