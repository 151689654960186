const module = angular.module('app');

module.factory('AuthService', (roleCheck, $http, $interval, $location) => {
    return {
        hasRole: roleName => roleCheck.get({ roleName: roleName }).$promise,
        ping: () => $http.get('./api/auth/ping').$promise,
    };
});

module.directive('hasRole', AuthService => ({
    link: (scope, elem, attrs) => {
        AuthService.hasRole(attrs.hasRole).then(data => {
            if (!data.hasRole) {
                elem.remove();
            }
        });
    },
}));

module.config($httpProvider => {
    $httpProvider.interceptors.push(($q, Message, $location, $window, $injector) => ({
        requestError: rejection => $q.reject(rejection),
        response: config => config || $q.when(config),
        responseError: rejection => {
            let title;
            let message;
            if (rejection.data && rejection.data.type === 'error') {
                message = rejection.data.message;
                if (rejection.data.ssn) {
                    message += ', ssn: ' + rejection.data.ssn;
                }
            } else {
                message = `status: ${rejection.status} ${rejection.config.method} ${
                    rejection.config.url
                }`;
            }
            switch (rejection.status) {
                case 503:
                    title = 'service_unavailable';
                    break;
                case 500:
                    title = 'server_error';
                    break;
                case 400:
                case 422:
                    title = 'validation_error';
                    break;
                case 404:
                    title = 'missing_resource';
                    break;
                case 401:
                    // Check if we've tried to refresh
                    if (!rejection.config.refreshRetried) {
                        // Try triggering a refresh
                        return fetch("./api/auth/refresh", {mode: 'cors', credentials: 'include'})
                            .then(() => {
                                // Refresh completed successfully, retrying with last request
                                const $http = $injector.get('$http');
                                return $http(angular.extend(rejection.config, {refreshRetried: true}));
                            });

                    }
                    // The app used to have two applications: one before the user was logged in and
                    // then the actual application once the user was logged in. Due to this a lot of
                    // code simply relies on always being in a logged in state and won't refetch data
                    // if the user logs out and then in. To avoid this we use location.href to force
                    // a page reload, thus making all data be re-fetched.
                    $window.location.href = './#/login';
                    rejection.handled = true;
                    break;
                default:
                    title = 'Communication Error';
            }

            return $q.reject(rejection);
        },
    }));
});
