var module = angular.module('benefits', ['csResources', 'ui.bootstrap']);

module.controller('CompanySummaryCtrl',
    function ($scope, $location, $filter, CompanyService, BenefitService, ReportService) {
        'use strict';

        var salaryExchangeHeaderKeys = ['First Name', 'Last Name', 'Insurance Company', 'Policy No',
             'Premium', 'currency'];
        var aggregateHeaderKeys = ['Insurance Company', 'Number of policies', 'Premium'];

        var translate = $filter('translate');

        $scope.selectedCompany = CompanyService.selectedCompany;

        $scope.type = BenefitService.type || 'aggregate';
        $scope.companySummaries = BenefitService.getInsuranceCompanySummaries();
        $scope.salaryExchanges = BenefitService.getSalaryExchanges();
        $scope.total = 0;
        $scope.predicate = 'issuerName';
        $scope.actionPath = './api/benefits/aggregate/report';

        function calculateTotal(rows) {
            return _.reduce(rows, function (memo, row) {
                return memo + row.premiumValuePerMonth;
            }, 0);
        }

        if ($scope.selectedCompany &&
            $scope.type === 'aggregate' &&
            $scope.companySummaries.length) {
            $scope.total = calculateTotal($scope.companySummaries);
        }

        $scope.search = function () {
            BenefitService.type = $scope.type;

            if ($scope.type === 'aggregate') {
                $scope.companySummaries = BenefitService.getInsuranceCompanySummaries($scope.selectedCompany.organizationNumber);
                $scope.companySummaries.$promise.then(function (rows) {
                    $scope.total = calculateTotal(rows);
                });
            } else {
                $scope.salaryExchanges = BenefitService.getSalaryExchanges($scope.selectedCompany.organizationNumber);
            }
        };

        $scope.setReportData = function () {
            var aggregateReportMeta = [{fieldName: 'issuerName'}, {fieldName: 'numberOfBenefits'}, {fieldName: 'premiumValuePerMonth'}],
                salaryExchangeMeta = [
                    {
                        fieldName: 'firstName',
                        rule: function (o) {
                            return o.authorizedToView === true ? o.firstName : 'Saknar behörighet';
                        }
                    },
                    {
                        fieldName: 'lastName',
                        rule: function (o) {
                            return o.authorizedToView === true ? o.lastName : '';
                        }
                    },
                    {
                        fieldName: 'insuranceCompany'
                    },
                    {
                        fieldName: 'policyNumber',
                        rule: function (o) {
                            return o.hidePolicyNumber === true ? 'Okänt' : o.policyNumber;
                        }
                    },
                    {
                        fieldName: 'premium.amount.value'
                    }, {
                        fieldName: 'premium.amount.unit'
                    }];

            var headers = null;
            if ($scope.type === 'aggregate') {
                headers = _.map(aggregateHeaderKeys, function (key) {return translate(key); });
                $scope.report = ReportService.getReport($scope.companySummaries, aggregateReportMeta, headers);
            } else {
                headers = _.map(salaryExchangeHeaderKeys, function (key) {return translate(key); });
                $scope.report = ReportService.getReport($scope.salaryExchanges, salaryExchangeMeta, headers);
            }
        };

        $scope.resultIsEmpty = function () {
            if ($scope.type === 'aggregate') {
                return $scope.companySummaries.$resolved && $scope.companySummaries.length === 0;
            } else {
                return $scope.salaryExchanges.$resolved && $scope.salaryExchanges.length === 0;
            }
        };

        $scope.$watch('type', function (newType, oldType) {
            if (newType === oldType) {
                return;
            }

            $scope.companySummaries = [];
            $scope.salaryExchanges = [];
            if (newType === 'aggregate') {
                $scope.actionPath = './api/benefits/aggregate/report';
            } else {
                $scope.actionPath = './api/benefits/salaryExchange/report';
            }
        });

    });


module.controller('InsuranceListCtrl', function ($scope, $routeParams, $timeout, BenefitService, CompanyService) {

    $scope.insuranceCompanyName = null;
    $scope.selectedCompany = CompanyService.selectedCompany;
    $scope.insurances = BenefitService.getInsuranceList($routeParams.issuerOrgNo, $scope.selectedCompany.organizationNumber);
    $scope.insurances.$promise.then(function (rows) {
        var first = _.first(rows);
        if (first) {
            $scope.insuranceCompanyName = first.insuranceCompany;
        }
    });

});
