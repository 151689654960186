angular.module('employee').directive('loading', function($http, $timeout, $translate) {
    return {
        restrict: 'E',
        template:'<div id="loadingOverlay" ng-cloak><div id="loading" class="text-muted fa-2x text-center"><span><p><i class="fa fa-spinner fa-spin"></i><br><strong>{{message}}</strong></p></span></div></div>',
        link: function(scope, element, attrs, fn) {
            element.hide();

            $translate('Loading').then(function(label) {
                scope.message = label;
            });

            scope.isLoading = function () {
                return $http.pendingRequests.length > 0;
            };

            $timeout(function() {
                scope.$watch(scope.isLoading, function (v) {
                    if(v) {
                        element.show();
                    }else{
                        element.hide();
                    }
                });
            }, 700);
        }
    };
});
