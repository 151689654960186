const app = angular.module('app');

app.controller(
    'LoginController',
    ($http, $httpParamSerializer, $scope, $window, $location, $translate, InfoMessage, $timeout) => {
        $scope.failed = false;

        $scope.showCode = false;

        $scope.userstatus = 'UNKNOWN';

        $scope.showMfaOnboarding = false;

        $scope.parameters = {
            username: null,
            password: null,
            code: null,
            countrycode: null,
            phone: null,
        };
        $scope.loginWarning = {
            header: '',
            desc: '',
        };

        $scope.alertInfo = null;
        InfoMessage.get(function (information) {
            $scope.alertInfo = information.propertyValue;
        });

        $scope.shouldObtainUsernamePassword = function () {
            return $scope.userstatus === 'UNKNOWN';
        };

        $scope.shouldOnboard = function () {
            return $scope.userstatus === 'ONBOARDING_REQUIRED';
        };

        $scope.shouldObtainOtp = function () {
            return $scope.userstatus === 'OTP_REQUIRED';
        };

        $scope.shouldShowAndFocusOtp = function () {
            if ($scope.userstatus === 'OTP_REQUIRED') {
                $timeout(function () {
                    // focus after rendering
                    document.getElementById('code').focus();
                }, 0);
                return true;
            }
            return false;
        };

        $scope.showAlert = function () {
            return $scope.alertInfo && !_.isEmpty($scope.alertInfo.trim());
        };

        $scope.authFailed = () => $scope.failed;

        $scope.loginWithPwd = function () {
            $scope.failed = false;
            hideLoginWarning();

            const credentials = $httpParamSerializer({
                username: $scope.parameters.username,
                password: $scope.parameters.password,
            });

            $http
                .post('api/auth/loginpwd' + getQueryParameters(), credentials, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                })
                .then(
                    (resp) => {
                        $scope.userstatus = resp.data.type;
                        openAppIfAuthorized();
                    },
                    err => {
                        console.error('Error, could not login', err);
                        $scope.failed = true;
                    }
                );
        };

        $scope.onboardMfa = function() {
            $scope.failed = false;
            hideLoginWarning();

            const credentials = $httpParamSerializer({
                username: $scope.parameters.username,
                password: $scope.parameters.password,
                countrycode: $scope.parameters.countrycode,
                phone: $scope.parameters.phone,
            });

            $http
                .post('api/auth/onboardmfa' + getQueryParameters(), credentials, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                    },
                })
                .then(
                    (resp) => {
                        $scope.userstatus = resp.data.type;
                    },
                    err => {
                        console.error('Error, could not login', err);
                        $scope.failed = true;
                    }
                );

        };

        $scope.loginWithCode = function () {
            $scope.failed = false;
            hideLoginWarning();

            const credentials = $httpParamSerializer({
                username: $scope.parameters.username,
                password: $scope.parameters.password,
                code: $scope.parameters.code
            });

            $http
                .post('api/auth/logincode' + getQueryParameters(), credentials, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                })
                .then(
                    (resp) => {
                        $scope.userstatus = resp.data.type;
                        // $window.location.href = '/companyservices/';
                        openAppIfAuthorized();
                    },
                    err => {
                        console.error('Error, could not login', err);
                        $scope.failed = true;
                    }
                );
        };

        function openAppIfAuthorized() {
            if (isAuthorized()) {
                $window.location.href = '/companyservices/';
            }
        }

        function isAuthorized() {
            return $scope.userstatus === 'AUTHORIZED';
        }

        function hideLoginWarning() {
            $scope.loginWarning.header = null;
            $scope.loginWarning.desc = null;
        }

        function getQueryParameters() {
            const xidParam = getParameterByName('xid');
            const brokerParam = getParameterByName('broker');

            if (xidParam && brokerParam) {
                let qurl = '?xid=' + xidParam;
                qurl += '&broker=' + brokerParam;
                return qurl;
            } else {
                return '';
            }
        }

        function getParameterByName(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
                results = regex.exec($window.location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }
    }
);
