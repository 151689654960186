const module = angular.module('app');

module.controller('InformationMessageCtrl', ($scope, InfoMessage, Message) => {

    $scope.currentInfoMessage = "";
    $scope.newInfoMessage = "";

    $scope.getInfoMessage = function () {
        InfoMessage.get(function (information) {
            $scope.currentInfoMessage = information.propertyValue;
        }, function (error) {
            $scope.currentInfoMessage = "";
            if (error.status === 404) {
                Message.error('', 'missing_resource', '');
            } else {
                Message.error('', 'server_error', '');
            }
        });
    };

    $scope.saveInformationMessage = function () {
        InfoMessage.save({"infoMessage": $scope.newInfoMessage}, function () {
                Message.success('', 'Changes-Done', 'ChangesDone');
                $scope.newInfoMessage = "";
                $scope.getInfoMessage();
            }, function (error) {
                if (error.status === 403) {
                    Message.error('', 'no_permission', '');
                } else if (error.status === 404) {
                    Message.error('', 'missing_resource', '');
                } else {
                    Message.error('', 'server_error', '');
                }
            }
        );
    }

    $scope.getInfoMessage();
});
