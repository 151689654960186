var module = angular.module('salarybulk', ['csResources', 'ngResource']);

module.controller('BulkResultCtrl', function($scope, CartService, $routeParams) {
    $scope.employees = CartService.list().slice(0);
    CartService.clear();
});

var ConfirmModalCtrl = function($scope, $uibModalInstance) {
    $scope.save = function() {
        $uibModalInstance.close();
    };
};

module.controller('SalaryBulkCtrl', function(
    $scope,
    $location,
    CartService,
    $http,
    CalendarService,
    EmployeeService,
    Message
) {
    $scope.employees = CartService.list();

    _.each($scope.employees, function(employee) {
        if (employee.newSalary) {
            employee.newSalary.amountSEK = null;
        }
        employee.note = null;
    });

    $scope.atwResultReceived = false;

    $scope.anyAtwStatementRequired = false;

    $scope.employments = [];

    $scope.command = {
        type: 'Yearly_Pensionable',
        effectiveDate: moment().startOf('month').format('YYYY-MM-DD')
    };

    $scope.model = {
        dates: CalendarService.generateMonthList(),
    };

    $scope.removeFromCart = function(employee) {
        CartService.remove(employee);
    };

    $scope.removeAll = function() {
        CartService.clear();
        $scope.employees = CartService.list();
    };

    $scope.submit = function() {
        $scope.salaryBulkForm.$setPristine();

        _.each($scope.employees, function(employee) {
            employee.effectiveDate = $scope.command.effectiveDate;
            employee.type = $scope.command.type;
        });

        $scope.employments = _.map($scope.employees, function(employee) {
            return {
                organisationsNummer: employee.company.organizationNumber,
                ssn: employee.ssn,
                amountSEK: employee.newSalary.amountSEK,
                effectiveDate: $scope.command.effectiveDate,
            };
        });

        $http
            .post('./api/employees/atwStatementRequiredBulk', $scope.employments)
            .then(response => {
                $scope.atws = response.data;
                $scope.atwResultReceived = true;

                _.each($scope.employees, employee => {
                    const employeeResponse = response.data[employee.ssn];
                    if (employeeResponse.resultat === 'FEL') {
                        Message.error('', employeeResponse.meddelande, 'ATWCheckFailed');
                    } else {
                        employee.atw = employeeResponse.resultat === 'KRAVS';
                        if (employee.atw) {
                            $scope.anyAtwStatementRequired = true;
                            employee.atws = [{
                                requestType: 'HEALTHY_TODAY',
                                statement: null,
                                atwNote: null
                            }];
                        }
                    }
                });

                if ($scope.anyAtwStatementRequired) {
                    $location.url('/atwbulk');
                } else {
                    EmployeeService.postNewSalaries($scope.employees).then(
                        response => {
                            const data = response.data;
                            if (data.success) {
                                $location.url('/bulkresult');
                            } else {
                                Message.error('', data.message, 'SalaryReportFailed');
                            }
                        },
                        function() {
                            Message.error('', 'report_failed', 'SalaryReportFailed');
                        }
                    );
                }
            });
    };
});

module.controller('AtwBulkCtrl', function(
    $scope,
    CartService,
    CompanyService,
    $http,
    $location,
    $filter,
    $routeParams,
    EmployeeService,
    $uibModal,
    Message
) {
    $scope.doConfirm = true;

    $scope.atwOnly = $routeParams.atwOnly === 'true';

    var postAtwBulk = function(employees) {
        var updatedEmployees = _.each(employees, employee => {
            _.each(employee.atws, atw => {
                var updatedWcase = JSON.parse(atw.wCase);
                updatedWcase.payload.statement = atw.statement;
                updatedWcase.payload.note = atw.atwNote;
                atw.wCase = JSON.stringify(updatedWcase);
            });
        });

        EmployeeService.attestWorkAbilityBulk(updatedEmployees).then(() =>
            $location.url('/atwbulkresult')
        );
    };

    $scope.submit = function() {
        $scope.doConfirm = false;

        if ($scope.atwOnly) {
            postAtwBulk($scope.employees);
        } else {
            EmployeeService.postNewSalaries($scope.employees).then(
                response => {
                    const data = response.data;
                    if (data.success) {
                        $location.url('/bulkresult');
                    } else {
                        Message.error('', data.message, 'SalaryReportFailed');
                    }
                },
                function() {
                    Message.error('', 'report_failed', 'SalaryReportFailed');
                }
            );
        }
    };

    $scope.getTooltip = function(employee) {
        return $filter('translate')('Statement of Work Ability.option.yes.help', employee);
    };

    $scope.checkAll = function(choice) {
        _.each($scope.employees, employee => {
            if(employee.atw) {
                _.each(employee.atws, atw => {
                    atw.statement = choice;
                });
            }
        });
    };

    $scope.showAtwDefinitionModal = function() {
        var atwDefinitionModal = $uibModal.open({
            template:'<form><div class="modal-header"><h2>{{\'Definition of Able to Work\' | translate}}</h2></div><div class="modal-body"><div class="well atw-well"><atw-text-wrapper></atw-text-wrapper></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" ng-click="save()">{{\'Close\' | translate}}</button></div></form>',
            controller: ConfirmModalCtrl,
            scope: $scope,
            backdrop: 'static',
        });
        return atwDefinitionModal.result;
    };

    $scope.cases = [];

    $scope.modCases = [];


    if (!$scope.atwOnly) {
        $scope.employees = CartService.list();
    } else {
        $scope.employees = [];

        var ssns = CartService.allSsns();

        $http.post('./api/employees/findOpenAtwCases', ssns).then(response => {
            $scope.cases = response.data;

            CartService.clear();

            var employeeCasesMap = _.groupBy(response.data, function(item) {
                return item.employee.ssn;
            });

            _.forEach(_.keys(employeeCasesMap), ssn => {
                var cartItem = {
                    ssn: ssn,
                    atw: true,
                    firstName: employeeCasesMap[ssn][0].employee.firstName,
                    lastName: employeeCasesMap[ssn][0].employee.lastName,
                    atws: _.map(employeeCasesMap[ssn], atw => {
                        return {
                            caseId: atw.id,
                            requestType: atw.payload.requestType,
                            wCase: JSON.stringify(atw),
                            statement: null,
                            atwNote: null
                        };
                    })
                };
                CartService.add(cartItem);
            });

            $scope.employees = CartService.list();
        });
    }

    var confirmModal = $uibModal.open({
        template:'<form><div class="modal-header"><h2>{{\'Definition of Able to Work\' | translate}}</h2></div><div class="modal-body"><p>{{\'Definition of Able to Work ingress\' | translate}}</p><div class="well atw-well"><atw-text-wrapper></atw-text-wrapper></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" ng-click="save()">{{\'I have read AbleToWork def\' | translate}}</button></div></form>',
        controller: ConfirmModalCtrl,
        scope: $scope,
        backdrop: 'static',
    });
});
