var module = angular.module("cart", ["csResources", "ngResource"]);

module.controller("CartCtrl",
    function($scope, CartService, $location) {

        $scope.employees = CartService.list();

        $scope.removeFromCart = function(employee) {
            CartService.remove(employee);
        };

        $scope.removeAll = function() {
            CartService.clear();
            $scope.employees = CartService.list();
        };
/*
        $scope.salaryBulk = function() {
            BulkService.startSalaryBulk();
        }

        $scope.atwBulk = function() {
            BulkService.startAtwBulk();
        }
        */
});
