var module = angular.module('app');

module.controller('ChangePasswordCtrl', function($scope, $http, Message) {
    $scope.form = {};

    $scope.pwdMismatch = false;
    $scope.incorrectPassword = false;

    $scope.$watch('confirmPassword', function(newValue, oldValue) {
        $scope.form.newPwd.$setValidity('match', $scope.newPassword === $scope.confirmPassword);
    });

    $scope.submit = function() {
        $http
            .post('./api/user/password', {
                oldPassword: $scope.oldPassword,
                newPassword: $scope.newPassword,
            })
            .then((response, status) => {
                Message.clearMessages();
                if (response.data === true) {
                    Message.success('', 'Password changed', 'PasswordChanged');
                    $scope.form.$setPristine();
                    $scope.newPassword = '';
                    $scope.oldPassword = '';
                    $scope.confirmPassword = '';
                }
                else if (response.data === false) {
                    Message.error('', 'Password_change_not_allowed', 'PasswordChangeError');
                    $scope.form.$setPristine();
                    $scope.newPassword = '';
                    $scope.oldPassword = '';
                    $scope.confirmPassword = '';

                }
            });
    };
});
