angular.module('benefits').directive("insurancesByEmployee", function () {
    return {
        restrict: 'E',
        scope: {
            insurances: '='
        },
        template:'<loading></loading><table class="table table-hover"><thead><tr><th><a href ng-click="predicate = \'firstName\'; reverse=!reverse">{{\'First Name\' | translate}}</a></th><th><a href ng-click="predicate = \'lastName\'; reverse=!reverse">{{\'Last Name\' | translate}}</a></th><th><a href ng-click="predicate = \'insuranceCompany\'; reverse=!reverse">{{\'Insurance Company\' | translate}}</a></th><th><a href ng-click="predicate = \'policyNumber\'; reverse=!reverse">{{\'Policy No\' | translate}}</a></th><th class="text-right"><a href ng-click="predicate = \'premium.amount.value\'; reverse=!reverse">{{\'Premium\' | translate}}</a></th></tr></thead><tbody><tr ng-repeat="row in insurances | orderBy:predicate:reverse"><td ng-if="!row.authorizedToView" colspan="2"><em>Saknar behörighet</em></td><td ng-if="row.authorizedToView"><a href="#/employees/{{row.anstallningsgruppExterntId}}/{{row.insuredSsn}}">{{row.firstName}}</a></td><td ng-if="row.authorizedToView"><a href="#/employees/{{row.anstallningsgruppExterntId}}/{{row.insuredSsn}}">{{row.lastName}}</a></td><td>{{row.insuranceCompany}}</td><td ng-if="!row.hidePolicyNo">{{row.policyNumber}}</td><td ng-if="row.hidePolicyNo">&lt;{{\'Unknown\' | translate}}&gt;</td><td class="text-right"><span ng-if="row.premium.amount">{{row.premium.amount.value | number}} {{\'unit-\' + row.premium.amount.unit | translate}}</span></td></tr></tbody><tfoot><tr style="border-top: 2px solid #dddddd;"><th class="text-right" colspan="6">{{getTotalAmount(insurances) | number}} kr</th></tr></tfoot></table>',
        link: function (scope) {
            scope.predicate = 'lastName';
            scope.reverse = false;

            scope.getTotalAmount = function(rows) {
                return _.reduce(rows, function (memo, row) {
                    if (row.premium && row.premium.amount && row.premium.amount.value) {
                        return memo + row.premium.amount.value;
                    }
                    return memo;
                }, 0);
            };
        }
    };
});
