var module = angular.module('casemodal', []);

module.controller("CaseModalCtrl",
    function($scope, $uibModal) {
        $scope.open = function (data, message) {
            $scope.case = data;
            $scope.message = message;

            var modalInstance = $uibModal.open({
                template:'<div class="modal-header"><h2 ng-if="case.employee">{{case.employee.firstName}} {{case.employee.lastName}}</h2><h2 ng-if="!case.employee">{{case.payload.firstName}} {{case.payload.lastName}}</h2><div>{{ message | translate }}</div></div><div class="modal-body"><div case-panel wcase="case" display-style="\'expanded\'"></div></div><div class="modal-footer"><button type="button" class="btn btn-default" ng-click="$close()">{{\'Close\' | translate}}</button></div>',
                scope: $scope
            });

            var removeListener = $scope.$on("$locationChangeStart", function() {
                removeListener();
                modalInstance.dismiss();
            });

            modalInstance.result['finally'](function (result) {
                removeListener();
            });
        };
    }
);

