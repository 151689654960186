var module = angular.module('statsControllers', ['csResources']);

// Varumärkesbärande färger
const maxm_brand_M1=  "#001B3C";
const maxm_brand_M2 =  "#031373";
const maxm_brand_M3 =  "#0F00A6";
const maxm_brand_M4 =  "#1A05F7";
const maxm_brand_M6 =  "#FC005C";

// ** Funktionsfärger
const maxm_brand_M5 =  "#FFAB00";
const maxm_brand_M7 =  "#FFA0D1";
const maxm_brand_M13 =  "#3C9664";
const maxm_brand_M8 =  "#FAFAFA";
const maxm_brand_M9 =  "#3B3B3B";
const maxm_brand_M10 =  "#707070";
const maxm_brand_M11 =  "#C2C2C2";
const maxm_brand_M12 =  "#E8E8E8";

var SelectCompanyModalCtrl = function ($scope, $uibModalInstance) {
    $scope.command = {selectedCompany: null};

    $scope.save = function () {
        $uibModalInstance.close($scope.command.selectedCompany);
    };
};

module.controller('StatsDetailCtrl', function ($scope, Stats, CompanyService, $filter, Preferences) {
    $scope.anstallningsgruppExterntId = null;
    $scope.selectedCompany = null;
    $scope.companies = CompanyService.companies;
    $scope.companies.$promise.then(function (companies) {
        Preferences.get(function (data) {
            $scope.anstallningsgruppExterntId = data.anstallningsgruppExterntId;
            if ($scope.anstallningsgruppExterntId) {
                $scope.selectedCompany = _.find(companies, function (company) {
                    return company.anstallningsgruppExterntId === $scope.anstallningsgruppExterntId;
                });
            }
            else
                if ($scope.companies.length > 0) {
                    $scope.selectedCompany = $scope.companies[0].anstallningsgruppExterntId;
            }
        });
    });

    Stats.get({category: 'info'}, function (data) {
        $scope.statInfo = data;
    });

    Stats.query({category: 'insurancecompany'}, function (data) {
        $scope.insuranceCompany = data;
    });

    Stats.get({category: 'company'}, function (data) {
        $scope.company = data;
    });

    Stats.query({category: 'dki'}, function (data) {
        $scope.dki = data;
    });

    Stats.get({category: 'activity'}, function (data) {
        $scope.activity = data;
    });
});

module.controller('StatsCtrl', function (
    $scope,
    Stats,
    CompanyService,
    $filter,
    Preferences,
    $uibModal,
    $translate
) {
    const colorArray = [
        maxm_brand_M1,
        maxm_brand_M4,
        maxm_brand_M11,
        maxm_brand_M6,
        maxm_brand_M9,
        maxm_brand_M3,
        maxm_brand_M7,
        maxm_brand_M2,
        maxm_brand_M10,
    ];

    const yTicks = [];
    const dkiMaxValue = 7;
    for (let i = 0; i < (dkiMaxValue + 1); i++) {
        yTicks.push(i);
    }
    $scope.icOptions = {
        chart: {
            type: 'pieChart',
            id: 'insurance-company-pie',
            height: 300,
            margin: {
                top: 70,
                right: 0,
                bottom: 20,
                left: 0
            },
            labelsOutside: true,
            showLabels: true,
            showLegend: true,
            tooltips: false,
            x: d => d.name,
            y: d => d.premie,
            labelType: 'percent',
            color: colorArray,
            tooltip: {
                contentGenerator: e => {
                    let sek = $filter('translate')('unit-sek');
                    let premie = $filter('number')(e.data.premie, 0);
                    return `<p> ${e.data.name}<br> ${premie} ${sek}</p>`
                },
            },
        },
    };

    $scope.dkiOptions = {
        chart: {
            type: 'multiBarChart',
            id: 'dki-bar',
            height: 350,
            duration: 500,
            forceY: [0, dkiMaxValue],
            x: d => d[0],
            y: d => d[1],
            showXAxis: false,
            xAxis: {
                showMaxMin: true,
                tickFormat: function (d) {
                    return d3.time.format('%x')(new Date(d));
                },
            },
            yAxis: {
                axisLabelDistance: -10,
                tickValues: yTicks
            },
            margin: {
                top: 70,
                bottom: 10,
                left: 30,
                right: 30
            },
            tooltips: true,
            showLegend: true,
            showControls: false,
            showValues: true,
            color: colorArray,
            tooltip: {
                contentGenerator: e => {
                    const series = $filter('translate')(`Q${e.data.key}`);
                    return `<i>${series}</i><b>: ${Math.round(e.data[1] * 10) / 10}</b>`;
                },
            },
        },
    };

    $translate('NO_DATA').then(translation => {
        $scope.icOptions.chart.noData = translation;
        $scope.dkiOptions.chart.noData = translation;
    });

    $scope.colors = {premie: maxm_brand_M1, number: maxm_brand_M1, activity: maxm_brand_M1, age: maxm_brand_M1};
    $scope.companyForStats = null;
    $scope.statInfo = null;
    $scope.insuranceCompany = null;
    $scope.company = null;
    $scope.dki = null;
    $scope.activity = null;
    $scope.selectedCompany = null;
    $scope.companies = CompanyService.companies;
    $scope.companies.$promise.then(function (companies) {
        Preferences.get((data) => {
            if (data.anstallningsgruppExterntId) {
                if (
                    _.findWhere(companies, {
                        anstallningsgruppExterntId: data.anstallningsgruppExterntId,
                    })
                ) {
                    $scope.companyForStats = data.anstallningsgruppExterntId;
                }
            }

            if (!$scope.companyForStats) {
                console.log("No company for stats")
            }
        });
    });

    $scope.shouldLeftPanelBeShown = function() {
       return $scope.selectedCompany && !$scope.selectedCompany.heartbeat;
    }

    $scope.$watch('companyForStats', (newValue, oldValue) => {
        if (newValue) {
            $scope.selectedCompany = _.find(
                $scope.companies,
                company => company.anstallningsgruppExterntId === newValue
            );

            Stats.query({category: 'insurancecompany'}, data => ($scope.insuranceCompany = data));
            Stats.query({category: 'dki'}, data => ($scope.dki = data));
            Stats.get({category: 'info'}, data => ($scope.statInfo = data));
            Stats.get({category: 'company'}, data => ($scope.company = data));
            Stats.get({category: 'activity'}, data => ($scope.activity = data));
        }
    });
});

module.directive('statsWidget', function () {
    return {
        scope: {
            type: '=type',
            quantity: '=quantity',
            colors: '=colors',
            link: '=',
        },
        template:'<div class="cs-right-panel"><h3>{{ title | translate }}</h3><div><p>{{ info | translate }}</p><a ng-if="quantity && link" style="font-size:200%;" ng-href="#/stats" uib-popover="{{ \'More-Info\' | translate }}" popover-trigger="\'mouseenter\'" class="ng-scope">{{quantity | number : 0}} {{ quantity_type | translate }}</a> <span ng-if="quantity && !link" style="font-size:200%;" class="ng-scope">{{quantity | number : 0}} {{ quantity_type | translate }}</span> <span ng-if="!quantity" style="font-size:200%;">-</span></div></div>',
        link: function (scope) {
            scope.title = scope.type + '-title';
            scope.info = scope.type + '-info';
            scope.quantity_type = scope.type + '-quantity_type';
            scope.color = scope.colors[scope.type];
        },
    };
});
